<template>
  <div class="content">
    <div  class="describe"  v-if="dataMsg.cj">
      <div class="describeText">描述：1个月内没有进店做过护理、没有进店购买产品消费的顾客、没有评选优秀案例且未毕业的顾客</div>
      <div class="dataMsg">
          <span>预警客户总数：{{dataMsg.allnum}}</span>
          <span>A类客户：{{dataMsg.cj.num}} | 占比：{{dataMsg.cj.zhanbi}}</span>
          <span>B类客户：{{dataMsg.yz.num}} | 占比：{{dataMsg.yz.zhanbi}}</span>
          <span>C类客户：{{dataMsg.pt.num}} | 占比：{{dataMsg.pt.zhanbi}} </span>
          <span>D类客户：{{dataMsg.zc.num}} | 占比：{{dataMsg.zc.zhanbi}}</span>
          </div>
    </div>
    <el-row class="bgW">
      <el-col :span="22">
        <!-- <el-tag type="info">提示：注释注释注释注释注释注释注释注释注释注释注释注释注释注释注释注释</el-tag> -->
        <!--检索条件-->
        <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="成交时间">
            <el-date-picker
              v-model="search.cj_time"
              type="date"
              class="w130"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>

          <el-form-item label="手机号">
            <el-input
              v-model="search.telephone"
              maxlength="30"
              class="w120"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          
          <el-form-item label="客户分类">
            <el-select v-model="search.member_classification" clearable class="w120">
              <el-option
                v-for="item in options.member_classification"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
		  <el-form-item label="疤痕类型">
		    <el-select v-model="search.scar_type_id" clearable class="w100">
		      <el-option
		        v-for="item in options.scar_type_id"
		        :key="item.id"
		        :label="item.name"
		        :value="item.id"
		      />
		    </el-select>
		  </el-form-item>
		  
          <el-form-item label="疤痕明细">
            <el-select v-model="search.scar_id" clearable class="w120">
              <el-option
                v-for="item in options.scar_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="最后客访时间">
            <el-date-picker
              v-model="search.last_kftime_start"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.last_kftime_end"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="">
            <el-switch
              v-model="value1"
              active-text="当年保有"
              inactive-text="累计保有"
              @change="changeCJTime">
            </el-switch>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <!-- <el-button type="primary" icon="el-icon-search" size="mini" @click="exportData('export')">搜索</el-button> -->
      </el-col>
    </el-row>

    <div class="accountCon">
      <div class="btnBox">
        <el-button
          type="primary"
          class="mb10"
          size="mini"
          v-if="!userInfo.shop_id"
          @click="exportData('export')"
        >导出数据</el-button>
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
          
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="姓名" prop="customer_name" width="100"  fixed/>
            <ElTableColumn label="手机号" prop="telephone" width="100" />
            <ElTableColumn label="成交时间" width="150" prop="cj_time" />
            
            
            <ElTableColumn label="回访时间" prop="last_kftime"  width="150" />
            <ElTableColumn label="门店/门诊" prop="shop_name" width="150" />
            <ElTableColumn label="性别" width="50" prop="sex" />
            <ElTableColumn label="年龄" width="50" prop="age" />
            <ElTableColumn label="进店时间" width="120" prop="new_join_shop_time" />
            <ElTableColumn label="来源" prop="source_name" />
            <ElTableColumn label="会员等级" prop="grade_name" />
            <ElTableColumn label="护理师" prop="nurse_name" />
            
            <ElTableColumn label="客户分类" prop="class_name" />
            <ElTableColumn label="消费时间" prop="final_consumption"  width="150" />
            <ElTableColumn label="服务时间" prop="final_service"  width="150" />
            
            <ElTableColumn label="疤痕类型" width="170" prop="scar_aggregate" />
            <ElTableColumn label="消费金额" prop="consumption" />
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn('客户详情',row.id)">查 看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pagination from "@/components/Pagination";
import {
  clientListLS,
  ssettingList,
  statusSetting,
  getGradeList,
  clientExport
} from "@/api/client";
import { getShopList } from "@/api/shop";

class Search {
  cj_time = "";
  customer_name = "";
  telephone = "";
  sex = "";
  agemin = "";
  agemax = "";
  source_id = "";
  birthday = "";
  effective_entry = "";
  membership_leve = "";
  shop_id = "";
  member_classification = "";
  source_name = "";
  scar_id = "";
  consumption = "";
  consumption_type = "";
  last_kftime_start = "";
  last_kftime_end = "";
  type = "";
  start_time = "";
  cj_type = "0";
  nurse_id = "";
}
class Options {
  sex = [
    { id: 0, name: "全部" },
    { id: 1, name: "男" },
    { id: 2, name: "女" }
  ];
  source_id = [];
  effective_entry = [
    { id: 0, name: "全部" },
    { id: 1, name: "是" },
    { id: 2, name: "否" }
  ];
  membership_leve = [];
  shop_id = [];
  member_classification = [];
  repair_progress = [];
  scar_id = [];
  consumption = [
    { id: 1, name: "大于等于" },
    { id: 2, name: "小于等于" },
    { id: 3, name: "等于" }
  ];
  
  scar_type_id = [
    {id: "1",name: "疤痕"},
    {id: "2",name: "青春痘"},
    {id: "3",name: "项目"}
  ];
  
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "AlarmClient",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(),
      options: new Options(),
      page: new Page(),
      list: [],
      idList: "",
      dataMsg: {},
      value1: false,
    };
  },
  filters: {
    statusFilter(val) {
      return ["启用", "禁用"][+val];
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    if(this.$route.query){
      this.search.shop_id = this.$route.query.shop_id;
      this.search.nurse_id = this.$route.query.nurse_id;
      this.search.type = this.$route.query.type ?? "";
      this.search.start_time = this.$route.query.start_time;
    }
    this.getList();
    this.getOptions();
  },
  methods: {
    // 获取选项
    getOptions() {
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 1) {
            that.options.scar_id.push(item);
          }  else if (item.category_id == 4) {
            that.options.source_id.push(item);
          } else if (item.category_id == 5) {
            that.options.repair_progress.push(item);
          } else if (item.category_id == 8) {
            that.options.member_classification.push(item);
          }
        });
      });
      getGradeList().then(res => {
        this.options.membership_leve = res.list;
      });
    },
    // 获取列表
    getList(option) {
      // console.log('111');
      // console.log(this.search);
      // return;
      
      if (option === "search") this.page = new Page();
      clientListLS({ ...this.search, ...this.page, is_join_shop: 4 ,is_export:0 }).then(
        res => {
          this.list = res.list;
          this.dataMsg = res.statisticsData;
          this.page.total = res.dataCount;
        }
      );
    },

    // 导出数据
    exportData(option) {
      // console.log(option);
      // var num = 0;
      
      

      // return;
      if (option === "export") this.page = new Page();
      clientListLS({ ...this.search, ...this.page, is_join_shop: 4 ,is_export:1 }).then(
        res => {
          // console.log(res);
          window.open(res.url);
        }
      );
    },

    // 搜索门店
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
    // 点击按钮详情
    tapBtn(routerName, id) {
      const query = { id: id, activeName: "first" };
      // this.$router.push({ name: routerName, query });
      let route = this.$router.resolve({ name: routerName, query });
      window.open(route.href, '_blank');
    },
    // 提示
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.getExport();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      clientExport({ id: this.idList, type: 5 }).then(res => {
        window.open(res.data.url);
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    },

    changeCJTime(e){
      if(e){
        // console.log('正确');
        this.search.cj_type = '1';
      }
      if(!e){
        // console.log('错误');
        this.search.cj_type = '0';
      }

      console.log(this.search);
      // return
      this.getList('search');
    },
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 10px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
  }
  
}
.describe{
  padding-left: 10px;
  .describeText{
    color: #666;
  }
.dataMsg {
    line-height: 30px;
    span {
      margin-left: 0px;
      font-weight: bold;
    }
  }
}

</style>
